var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import styled from '@emotion/styled';
import MuiLink from '@mui/material/Link';
const LinkComponent = styled(MuiLink)(() => ({
    cursor: 'pointer',
}));
/**
 * component is used for hrefs outside Endrex app
 */
const Link = (_a) => {
    var { onClick = undefined, disabled = false } = _a, props = __rest(_a, ["onClick", "disabled"]);
    const handleClick = (ev) => {
        if (!disabled) {
            onClick === null || onClick === void 0 ? void 0 : onClick(ev);
        }
    };
    return (_jsx(LinkComponent
    // eslint-disable-next-line react/jsx-props-no-spreading
    , Object.assign({}, props, { onClick: handleClick })));
};
export default Link;
