import { jsx as _jsx } from "react/jsx-runtime";
import styled from '@emotion/styled';
import MuiTabs from '@mui/material/Tabs';
import MuiTab from '@mui/material/Tab';
import { getTabsStyles } from './styles';
const TabsComponent = styled(MuiTabs, { shouldForwardProp: propName => propName !== 'tabsVariant' })(props => ({
    minHeight: getTabsStyles(props.theme).variants[props.tabsVariant].minHeight,
    '& .MuiTabs-scroller': {
        display: 'inline-flex',
    },
    '& .MuiTabs-flexContainer': {
        display: 'inline-flex',
        borderBottom: getTabsStyles(props.theme).variants[props.tabsVariant].borderBottom,
        padding: getTabsStyles(props.theme).variants[props.tabsVariant].padding,
        borderRadius: getTabsStyles(props.theme).variants[props.tabsVariant].borderRadius,
        backgroundColor: getTabsStyles(props.theme).variants[props.tabsVariant].backgroundColor,
        gap: getTabsStyles(props.theme).variants[props.tabsVariant].gap,
    },
    '& .MuiTabs-indicator': {
        top: getTabsStyles(props.theme).variants[props.tabsVariant]
            .indicatorTopPosition,
        backgroundColor: getTabsStyles(props.theme).variants[props.tabsVariant]
            .indicatorBackgroundColor,
        height: getTabsStyles(props.theme).variants[props.tabsVariant].indicatorHeight,
        borderRadius: getTabsStyles(props.theme).variants[props.tabsVariant].borderRadius,
    },
}));
const getTabPseudoElement = (variant) => (variant === 'chart-tabs' ? ({
    position: 'absolute',
    right: 0,
    content: "''",
    borderLeft: '1px solid #EFF1F3',
    height: 20,
}) : ({}));
const TabComponent = styled(MuiTab, { shouldForwardProp: propName => propName !== 'variant' })(props => ({
    position: 'relative',
    color: getTabsStyles(props.theme).variants[props.variant].tabColor,
    minWidth: getTabsStyles(props.theme).variants[props.variant].tabMinWidth,
    minHeight: getTabsStyles(props.theme).variants[props.variant].tabMinHeight,
    padding: getTabsStyles(props.theme).variants[props.variant].tabPadding,
    textTransform: 'none',
    '&:hover': {
        color: getTabsStyles(props.theme).variants[props.variant].tabHoverColor,
    },
    '&.Mui-selected': {
        color: getTabsStyles(props.theme).variants[props.variant].selectedTabColor,
        cursor: 'default',
        zIndex: 1,
    },
    '& .MuiTypography-root': {
        zIndex: getTabsStyles(props.theme).variants[props.variant].textZIndex,
    },
    '&:not(.Mui-selected)::after': Object.assign({}, getTabPseudoElement(props.variant)),
}));
const Tabs = ({ className = undefined, activeTab = undefined, tabs, onChange = () => { }, variant = 'default', }) => {
    const handleActiveTabChange = (_, newActiveTab) => {
        if (activeTab !== newActiveTab) {
            onChange(newActiveTab);
        }
    };
    return (_jsx(TabsComponent, { className: className, value: activeTab, onChange: handleActiveTabChange, tabsVariant: variant, children: tabs.map((tab, index) => (_jsx(TabComponent, { label: tab.label, value: tab.value, variant: variant }, tab.key || tab.value || index))) }));
};
export default Tabs;
